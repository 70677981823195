import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from 'src/app/shared/shared.module';
import {EARoutingModule} from './ea-routing.module';
// import { CarouselModule } from 'ngx-owl-carousel-o';
// import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
// import { FormsModule } from '@angular/forms';
// import {SWIPER_CONFIG, SwiperConfigInterface, SwiperModule} from 'ngx-swiper-wrapper-v-13';
import {NgxTypedJsModule} from 'ngx-typed-js';
// import { CountToModule } from 'angular-count-to';
import {NgxMasonryModule} from 'ngx-masonry';
// import { LightboxModule } from 'ngx-lightbox';
import {FeatherModule} from 'angular-feather';
import {allIcons} from 'angular-feather/icons';
// import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
// import { SeoService } from 'src/app/helper/SeoService';
// CONST
// const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
//   direction: 'horizontal',
//   slidesPerView: 'auto'
// };
// ***************************** //

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        EARoutingModule,
        FeatherModule.pick(allIcons),
        NgbModule,
        // FormsModule,
        // SwiperModule,
        NgxTypedJsModule,
        NgxMasonryModule,
        // LightboxModule,
        SharedModule
    ],
    exports: [
        FeatherModule,
    ],
    // providers: [
    //   SeoService,
    //   {
    //     provide: SWIPER_CONFIG,
    //     useValue: DEFAULT_SWIPER_CONFIG
    //   },
    // ]
})
export class EaModule {
}
