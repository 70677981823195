import { Component, OnInit, Input, HostListener } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})

/***
 * Header Component
 */
export class HeaderComponent implements OnInit {
  /** nav-container animation class used to cancel/enable the animation when scrolled
   */
  animationClass: "animate" | "" = "animate";

  // for window scroll events
  @HostListener("window:scroll", ["$event"])
  onScroll(_event) {
    // console.log("event: ", event);
    // console.log("window: ", window.scrollY);

    this.animationClass = window.scrollY > 200 ? "" : 'animate';
  }
  
  /** keep nav animation when opwning dropdowns and hovering them */
  handleDropdownOpen(_isOpen:boolean) {
    document.getElementsByClassName('nav-container animate')[0].classList.toggle('keep-hover')
  }
  show: boolean = false
  drop: boolean = false
  contact: boolean = false
  support: boolean = false

  toggle(change: boolean) {
    return !change
  }

  i = 0

  @HostListener('document:click', ['$event'])
  public documentClick(event: Event): void {
    if(!(event.target as Element).classList.contains('lang')){
      this.show = false
    }
    if(!(event.target as Element).classList.contains('currency')){
      this.drop = false
    }
    if(!(event.target as Element).classList.contains('contact')){
      this.contact = false
    }
    if(!(event.target as Element).classList.contains('support')){
      this.support = false
    }
  }

  items: string[] = ['Français', 'English'];
  lang = [
    {
      id: 1,
      name: 'Français',
      img : 'assets/images/lang/fra-flag.png'
    },
    {
      id:2,
      name: 'English',
      img : 'assets/images/lang/eng-flag.png'
    },
  ]
  devis = [
    {
      name: 'EUR'
    },
    {
      name: 'MAD'
    },
    {
      name: 'USD'
    }
  ]
  selectedDevis: string = 'EUR'
  selectedItem: string = 'Français';
  selectedImg: string = 'assets/images/lang/fra-flag.png';

  selectLang(item) {
    this.selectedItem = item.name;
    this.selectedImg = item.img;
    this.show = !this.show;
  }
  selectDevis(item) {
    this.selectedDevis = item.name;
    this.drop = !this.drop;
  }

  webmail="https://hosting.e-ambition.net:2096"
  espacePraticien="https://portal.e-ambition.com/"

  redirectPage(link: string) {
    window.open(
      link, '_blank'
    )
  }


  @Input() navClass: string;
  @Input() buttonList: boolean;
  @Input() sliderTopbar: boolean;
  @Input() isdeveloper: boolean;
  @Input() shopPages: boolean;

  constructor(private router: Router, private modalService: NgbModal) {
    this.router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this._activateMenuDropdown();
      }
    });
  }
  public getScreenWidth: any;
  isCondensed = false;

  ngAfterViewInit() {
    this._activateMenuDropdown();
  }

  ngOnInit(): void {
    this.getScreenWidth = window.innerWidth;
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.getScreenWidth = window.innerWidth;
  }

  _activateMenuDropdown() {
    /**
     * Menu activation reset
     */
    const resetParent = (el) => {
      el.classList.remove("active");
      const parent = el.parentElement;

      /**
       * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
       * We should come up with non hard coded approach
       */
      if (parent) {
        parent.classList.remove("active");
        const parent2 = parent.parentElement;
        if (parent2) {
          parent2.classList.remove("active");
          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove("active");
            const parent4 = parent3.parentElement;
            if (parent4) {
              const parent5 = parent4.parentElement;
              parent5.classList.remove("active");
            }
          }
        }
      }
    };
    let links = document.getElementsByClassName("nav-link-ref");
    let matchingMenuItem = null;
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < links.length; i++) {
      // reset menu
      resetParent(links[i]);
    }
    for (let i = 0; i < links.length; i++) {
      if (window.location.pathname === links[i]["pathname"]) {
        matchingMenuItem = links[i];
        break;
      }
    }

    if (matchingMenuItem) {
      matchingMenuItem.classList.add("active");
      const parent = matchingMenuItem.parentElement;

      /**
       * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
       * We should come up with non hard coded approach
       */
      if (parent) {
        parent.classList.add("active");
        const parent2 = parent.parentElement;
        if (parent2) {
          parent2.classList.add("active");
          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.add("active");
            const parent4 = parent3.parentElement;
            if (parent4) {
              const parent5 = parent4.parentElement;
              parent5.classList.add("active");

              document.getElementById("navigation").style.display = "none";
              this.isCondensed = false;
            }
          }
        }
      }
    }
  }

  /**
   * Window scroll method
   */
  // tslint:disable-next-line: typedef
  windowScroll() {
    if (
      document.body.scrollTop > 50 ||
      document.documentElement.scrollTop > 50
    ) {
      document.getElementById("topnav").classList.add("nav-sticky");
    } else {
      document.getElementById("topnav").classList.remove("nav-sticky");
    }
    if (document.getElementById("back-to-top")) {
      if (
        document.body.scrollTop > 100 ||
        document.documentElement.scrollTop > 100
      ) {
        document.getElementById("back-to-top").style.display = "inline";
      } else {
        document.getElementById("back-to-top").style.display = "none";
      }
    }
  }
  /**
   * Toggle menu
   */
  toggleMenu() {
    this.isCondensed = !this.isCondensed;
    if (this.isCondensed) {
      document.getElementById("navigation").style.display = "block";
    } else {
      document.getElementById("navigation").style.display = "none";
    }
  }

  /**
   * Menu clicked show the submenu
   */
  onMenuClick(event) {
    if(this.getScreenWidth < 995){
      event.preventDefault();
      const nextEl = event.target.nextSibling.nextSibling.nextSibling;
      if (nextEl && !nextEl.classList.contains("open")) {
        const parentEl = event.target.parentNode;
        if (parentEl) {
          parentEl.classList.remove("open");
        }
        nextEl.classList.add("open");
      } else if (nextEl) {
        nextEl.classList.remove("open");
      }
      return false;
    }
  }

  developerModal(content) {
    this.modalService.open(content, { size: "lg", centered: true });
  }

  wishListModal(content) {
    this.modalService.open(content, { centered: true });
  }
  onChangeTheme(theme: string){
    console.log(theme)
    let color = ''
    switch (theme) {
      case "agency":
        color = "skobleoff"
        document
        .getElementById('color-opt')
        .setAttribute('href', './assets/css/colors/' + color + '.css');
        break;
      case "techit":
        color = "red"
        document
        .getElementById('color-opt')
        .setAttribute('href', './assets/css/colors/' + color + '.css');
        break;
        case "cloud":
          color = "yellow"
          document
          .getElementById('color-opt')
          .setAttribute('href', './assets/css/colors/' + color + '.css');
          break;
      default:
        break;
    }
  }
}
