import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class MailingService {
  private _url: string;
  constructor(private _httpClient: HttpClient) {
    this._url = environment.url_api;
  }
/*
  sendMessage(body) {
    return this.http.post(this._url + '/operation/action', body);
  }

  newsletter_subscribe(body) {
    return this.http.post(this._url + '/operation/action2', body);
  }*/

  /*
   * Get Practices
   *
   * @returns Promise<any>
   */
  newsletter_subscribe(data): Promise<any> {

    let httpParams = new HttpParams();
    
    httpParams = httpParams.append('first_name', data.directory.first_name);


    return new Promise((resolve) => {
      this._httpClient.post(this._url + '/operation/action',  httpParams,
      {
          responseType: 'json',
          withCredentials: true,
          observe: 'response'
      }).subscribe((response: any) => {
          console.log('action2 ==> ', response);
          if(response.body.code === 1){
            resolve(response.body.data);
          }else{
            resolve([]);
          }
      });
    });
  }
}
