import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators  } from '@angular/forms';
import { MailingService } from 'src/app/services/mailing.service';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})

/***
 * Footer Component
 */
export class FooterComponent implements OnInit {

  @Input() footerVariant: string;
  @Input() hideFooter: boolean;

  constructor(private mailService: MailingService) { }

  //Get Year
  year = new Date().getFullYear()

  userEmail = new FormGroup({
    email: new FormControl('',[
      Validators.required,
      Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")])
    });

  get email(){
    return this.userEmail.get('email')
  }

  sendMail(mail){
    console.log(mail)
    this.mailService.newsletter_subscribe(mail).then((data)=>{
      console.log(data)
    })
  }

  ngOnInit(): void {
  }

}
