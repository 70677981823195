import { Injectable } from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';


@Injectable({
    providedIn: 'root'
})
export class SeoService {

    constructor(private meta: Meta,
                private title: Title) {
    }

    generateTags(config): void {
        // default values
        config = {
            title: 'E-AMBITION • Vos ambitions prennent vie',
            description: 'E-AMBITION est une société spécialisée dans l\'accompagnement, la modélisation et la réalisation de projets informatiques.',
            image: 'http://imagepardefaut.com',
            ...config
        };
        this.meta.addTags([
            {name: 'description', content: config.description},
            {name: 'keywords', content: config.description},
        ]);

        this.meta.updateTag({name: 'twitter:card', content: 'summary'});
        this.meta.updateTag({name: 'twitter:site', content: '@twitter'});
        this.meta.updateTag({name: 'twitter:title', content: config.title});
        this.meta.updateTag({name: 'twitter:description', content: config.description});
        this.meta.updateTag({name: 'twitter:image', content: config.image});

        this.meta.updateTag({property: 'og:type', content: 'article'});
        this.meta.updateTag({property: 'og:site_name', content: 'E-AMITION'});
        this.meta.updateTag({property: 'og:title', content: config.title});
        this.meta.updateTag({property: 'og:description', content: config.description});
        this.meta.updateTag({property: 'og:image', content: config.image});
        this.meta.updateTag({property: 'og:url', content: `https://monurl/`});

        this.title.setTitle(config.title);
    }
}
