
<!-- Navbar STart -->

<div class="nav-container" [ngClass]="animationClass"  [ngClass]="show || drop || contact || support? [animationClass, 'keep-hover'] : animationClass">

  <div class="sub-nav">
    <div class="items-container">

      <!-- left-items -->
      <div class="items">

        <!-- ea-icons -->
        <div class="ea-icons">

          <!--  -->
          <div class="icon">
            <a href="/" class="icon-link" data-bs-toggle="tooltip" title="E-AMBITION">
              <img src="assets/images/logo/ea.svg" alt="" class="icon-image face front" loading="lazy">
              <img src="assets/images/logo/ea.svg" alt="" class="icon-image face right" loading="lazy">
            </a>
          </div>

          <div class="icon">
            <a href="/tech-it" class="icon-link" data-bs-toggle="tooltip" title="TECH IT">
              <img src="assets/images/logo/ea-s.svg" alt="" class="icon-image face front" loading="lazy">
              <img src="assets/images/logo/ea-s.svg" alt="" class="icon-image face right" loading="lazy">
            </a>
          </div>

          <div class="icon">
            <a href="/agency" class="icon-link" data-bs-toggle="tooltip" title="AGENCY">
              <img src="assets/images/logo/ea-a.svg" alt="" class="icon-image face front" loading="lazy">
              <img src="assets/images/logo/ea-a.svg" alt="" class="icon-image face right" loading="lazy">
            </a>
          </div>

          <div class="icon">
            <a href="/cloud" class="icon-link" data-bs-toggle="tooltip" title="CLOUD">
              <img src="assets/images/logo/ea-h.svg" alt="" class="icon-image face front" loading="lazy">
              <img src="assets/images/logo/ea-h.svg" alt="" class="icon-image face right" loading="lazy">
            </a>
          </div>

          <div class="icon">
            <a href="/training" class="icon-link" data-bs-toggle="tooltip" title="TRAINING">
              <img src="assets/images/logo/ea-t.svg" alt="" class="icon-image face front" loading="lazy">
              <img src="assets/images/logo/ea-t.svg" alt="" class="icon-image face right" loading="lazy">
            </a>
          </div>
        </div>
        
        <!-- language-picker -->
        <!-- <div ngbDropdown (openChange)="handleDropdownOpen($event)" container="body">
          <button type="button" id="lang-dropdown-btn" class="btn btn-outline-primary btn-sm dropdown-btn" ngbDropdownToggle>
            <span class="item-icon"><img src="https://beta.e-ambition.net/assets/ea-theme/img/fra-flag.png" /></span>
            <span>Français</span>
          </button>
          <div ngbDropdownMenu class="dropdown-container">
            <button ngbDropdownItem class="lang-dropdown-item">
              <span><img src="assets/images/lang/fra-flag.png" /></span>
              <span>Français</span>
            </button>
            <button ngbDropdownItem class="lang-dropdown-item">
              <span><img src="assets/images/lang/eng-flag.png" /></span>
              <span>English</span>
            </button>
          </div>
        </div> -->

        <!-- The main div containing the dropdown -->
        <div class="relative lang">
          <!-- The div containing the selected value and an icon to visually indicate if the dropdown is open or closed -->
          <div (click)="show = toggle(show)" class="lang cursor-pointer relative w-fit px-2 py-1 border-white rounded-sm border border-1 flex justify-center items-center text-[11px] text-white font-bold bg-white/20">
            <!-- <div><img class="lang" src="{{selectedImg}}" loading="lazy"/></div> -->
            <div><img class="lang" src="assets/images/lang/fra-flag.png" alt="french" width="16" height="12"/></div>
              <div class="mx-2 lang">
                <!-- {{selectedItem}} -->Français
              </div>
              <div>
                <svg aria-hidden="true" focusable="false"s data-prefix="fas" data-icon="caret-down" class="w-2 lang" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" >
                <path class="lang"
                  fill="currentColor"
                  d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"
                ></path>
              </svg>
              </div>
          </div>
          <!-- The div containing the available items -->
          <div *ngIf="show" class="absolute w-fit py-1 shadow-md text-xs mt-1 rounded-b rounded-t rounded-sm cursor-pointer bg-white font-bold">
            <div (click)="selectLang(item)" *ngFor="let item of lang" class="py-1 px-3 w-full flex items-center" [ngClass]="item.id==2? 'cursor-not-allowed text-slate-500 bg-gray-300' : 'hover:bg-blue-200'">
                <span><img class="mr-2" src="{{ item.img }}"/></span>
                <span class="">{{ item.name }}</span>
            </div>
          </div>
        </div>

        <!-- The div containing the selected value and an icon to visually indicate if the dropdown is open or closed -->
        <!-- <div class="relative currency">
          <div (click)="drop = toggle(drop)" class="currency cursor-pointer relative w-fit px-2 py-1 border-white rounded-sm border border-1 flex justify-center items-center text-[11px] text-white bg-white/20">
              <span class="mr-1 currency">
                {{selectedDevis}}
              </span>
              <div>
                <svg aria-hidden="true" focusable="false"s data-prefix="fas" data-icon="caret-down" class="w-2 currency" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" >
                <path
                  class="currency"
                  fill="currentColor"
                  d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"
                ></path>
              </svg>
              </div>
          </div>
          <div *ngIf="drop" class="absolute w-fit py-1 shadow-md text-[11px] mt-1 rounded-b rounded-t cursor-pointer bg-white font-bold">
            <div (click)="selectDevis(item)" *ngFor="let item of devis" class="py-1 px-3 w-full hover:bg-gray-200 flex items-center">
              <span class="">{{ item.name }}</span>
            </div>
          </div>
        </div> -->

        <!-- currency-picker -->
        <!-- <div ngbDropdown (openChange)="handleDropdownOpen($event)" container="body">
          <button type="button" class="btn btn-outline-primary btn-sm dropdown-btn" ngbDropdownToggle>EUR</button>
          <div ngbDropdownMenu class="dropdown-container">
            <button ngbDropdownItem>EUR</button>
            <button ngbDropdownItem>USD</button>
            <button ngbDropdownItem>MAD</button>
          </div>
        </div> -->

      </div>

      <!-- right-items -->
      <div class="items items-right">
        <div class="relative contact">
          <!-- The div containing the selected value and an icon to visually indicate if the dropdown is open or closed -->
          <div (click)="contact = toggle(contact)" class="contact cursor-pointer relative w-fit px-2 py-1 border-white rounded-sm border border-1 flex justify-center items-center text-[11px] text-white bg-white/20">
              <span class="mr-1 contact">
                Contact
              </span>
              <div>
                <svg aria-hidden="true" focusable="false"s data-prefix="fas" data-icon="caret-down" class="w-2 contact" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" >
                <path
                  class="contact"
                  fill="currentColor"
                  d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"
                ></path>
              </svg>
              </div>
          </div>
          <!-- The div containing the available items -->
          <div *ngIf="contact" class="absolute py-1 shadow-md text-sm mt-1 rounded-b rounded-t cursor-pointer bg-white font-bold max-w-max">
            <div class="py-1 px-3 w-full hover:bg-gray-200 flex items-center min-w-max" routerLink="/page-maintenance/">
              <span class='item-icon flex'><i-feather name="edit" class="fea icon-sm"></i-feather></span> 
              <span class="">Demande de devis</span>
            </div>
            <div class="py-1 px-3 w-full hover:bg-gray-200 flex items-center min-w-max" routerLink="/page-maintenance">
              <span class='item-icon flex'><i-feather name="book-open" class="fea icon-sm"></i-feather></span>
              <span class="">Boîte à idées</span>
            </div>
            <div class="py-1 px-3 w-full hover:bg-gray-200 flex items-center min-w-max" routerLink="/contactez-nous">
              <span class='item-icon flex'><i-feather name="phone-call" class="fea icon-sm"></i-feather></span>
              <span class="">Contactez-nous</span>
            </div>
          </div>
        </div>
        <!-- contact -->
        <!-- <div ngbDropdown (openChange)="handleDropdownOpen($event)" container="body">
          <button type="button" class="btn btn-outline-primary btn-sm dropdown-btn" ngbDropdownToggle>Contact</button>
          <div ngbDropdownMenu class="">
            <button ngbDropdownItem>
              <span class='item-icon'><i-feather name="edit" class="fea icon-sm"></i-feather></span> 
              <span>Demande de devis</span>
            </button>
            <button ngbDropdownItem>
              <span class='item-icon'><i-feather name="book-open" class="fea icon-sm"></i-feather></span> 
              <span>Boîte à idées</span>
            </button>
            <button ngbDropdownItem routerLink="contactez-nous">
              <span class='item-icon'><i-feather name="phone-call" class="fea icon-sm"></i-feather></span> 
              <span>Contactez-nous</span>
            </button>
          </div>
        </div> -->

        <!-- support -->
        <!-- <div class="items items-right"> -->
          <div class="relative support">
            <!-- The div containing the selected value and an icon to visually indicate if the dropdown is open or closed -->
            <div (click)="support = toggle(support)" class="support cursor-pointer relative w-fit px-2 py-1 border-white rounded-sm border border-1 flex justify-center items-center text-[11px] text-white bg-white/20">
                <span class="mr-1 support">
                  Support
                </span>
                <div>
                  <svg aria-hidden="true" focusable="false"s data-prefix="fas" data-icon="caret-down" class="w-2 support" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" >
                  <path
                    class="support"
                    fill="currentColor"
                    d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"
                  ></path>
                </svg>
                </div>
            </div>
            <!-- The div containing the available items -->
            <div *ngIf="support" class="absolute py-1 shadow-md text-sm mt-1 rounded-b rounded-t cursor-pointer bg-white font-bold max-w-max">
              <div class="py-1 px-3 w-full hover:bg-gray-200 flex items-center min-w-max" routerLink="/contactez-nous">
                <span class='item-icon flex'><i-feather name="phone-call" class="fea icon-sm"></i-feather></span> 
                <span class="">Nous contacter</span>
              </div>
              <div class="py-1 px-3 w-full hover:bg-gray-200 flex items-center min-w-max" routerLink="/page-maintenance">
                <span class='item-icon flex'><i-feather name="help-circle" class="fea icon-sm"></i-feather></span>
                <span class="">Centre d'aide</span>
              </div>
              <div class="py-1 px-3 w-full hover:bg-gray-200 flex items-center min-w-max" routerLink="/page-maintenance">
                <span class='item-icon flex'><i-feather name="message-circle" class="fea icon-sm"></i-feather></span>
                <span class="">Live chat</span>
              </div>
              <div class="py-1 px-3 w-full hover:bg-gray-200 flex items-center min-w-max" routerLink="/page-maintenance">
                <span class='item-icon flex'><i-feather name="bar-chart" class="fea icon-sm"></i-feather></span>
                <span class="">Etat du reseau</span>
              </div>
            </div>
          </div>
        <!-- <div ngbDropdown (openChange)="handleDropdownOpen($event)" container="body">
          <button type="button" class="btn btn-outline-primary btn-sm dropdown-btn" ngbDropdownToggle>Support</button>
          <div ngbDropdownMenu class="">
            <button ngbDropdownItem>
              <span class='item-icon'><i-feather name="phone-call" class="fea icon-sm"></i-feather></span> 
              <span>Nous contacter</span>
            </button>
            <button ngbDropdownItem>
              <span class='item-icon'><i-feather name="help-circle" class="fea icon-sm"></i-feather></span> 
              <span>Centre d'aide</span>
            </button>
            <button ngbDropdownItem>
              <span class='item-icon'><i-feather name="message-circle" class="fea icon-sm"></i-feather></span> 
              <span>Live chat</span>
            </button>
            <button ngbDropdownItem>
              <span class='item-icon'><i-feather name="bar-chart" class="fea icon-sm"></i-feather></span> 
              <span>Etat du reseau</span>
            </button>
          </div>
        </div>  -->

        <!-- extra-bnt-nav -->
        <div class="extra-btn-nav-container">
          <!-- <button class="" routerLink="/page-maintenance">
            <span class="item-icon"><i-feather name="shopping-bag" class="fea icon-sm"></i-feather></span>
            <span>Panier</span>
          </button> -->
          <button class="" (click)="redirectPage(espacePraticien)">
            <span class="item-icon"><i-feather name="user" class="fea icon-sm"></i-feather></span>
            <span>Espace client</span>
          </button>
          <button class="" (click)="redirectPage(webmail)">
            <span class="item-icon"><i-feather name="mail" class="fea icon-sm"></i-feather></span>
            <span>WebMail</span>
          </button>
        </div>
        
      </div>

    </div>
  </div>

  <header id="topnav" class="defaultscroll sticky main-nav" (window:scroll)="windowScroll()" [class]="navClass"
    [ngClass]="{'tagline-height': sliderTopbar == true}">
    <div class="">
      <!-- Logo container-->
      <div>
        <a class="logo" routerLink="/" *ngIf="navClass !== 'nav-light'" aria-label="logo">
          <img src="assets/images/logo.svg" class="l-dark logo-light-mode" width="250" alt="logo" height="52">
          <img src="assets/images/logo-light.svg" class="logo-dark-mode" width="250" alt="logo-light" height="52">
        </a>

        <a class="logo" routerLink="/" *ngIf="navClass === 'nav-light'" aria-label="logo2">
          <img src="assets/images/logo.svg" class="l-dark" width="250" alt="logo" height="52">
          <img src="assets/images/logo-light.svg" class="l-light" width="250" alt="logo-light" height="52">
        </a>
      </div>

      <!-- <span *ngIf="!buttonList && !isdeveloper && !shopPages">
        <div class="buy-button">
          <ng-template [ngIf]="navClass === 'nav-light'">
            <a href="javascript: void(0);" class="btn btn-primary login-btn-primary">e-ambition.com</a>
            <a href="javascript: void(0);" class="btn btn-light login-btn-light">e-ambition.com</a>
          </ng-template>
          <ng-template [ngIf]="navClass !== 'nav-light'">
            <a href="javascript: void(0);" class="btn btn-primary">e-ambition.com</a>
          </ng-template>
        </div>
      </span> -->
      <!--end login button-->

      <div class="menu-extras">
        <div class="menu-item">
          <!-- Mobile menu toggle-->
          <button class="navbar-toggle" id="isToggle" (click)="toggleMenu()" [ngClass]="{'open': isCondensed === true}">
            <div class="lines">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </button>
          <!-- End mobile menu toggle-->
        </div>
      </div>

      <div id="navigation">

        <!-- Navigation Menu-->
        <ul class="navigation-menu" [class]="navClass">
          <!-- <li><a routerLink="/" class="nav-link-ref">Ressources</a></li> -->

          <li id="li-apropos" class="has-submenu">
            <span (click)="onMenuClick($event)" class="link cursor-pointer" routerLink="/">À propos</span>
            <span class="menu-arrow"></span>
            <small class="tts-nav-arrow"></small>
            <ul id="apropos" [ngClass]="getScreenWidth < 995 ? 'submenu megamenu first border-top' : 'rounded shadow-sm border-top list-unstyled'">
              <!-- <a href="javascript:void(0)" (click)="onMenuClick($event)">NOUS CONNAÎTRE</a> -->
              <div class="" [ngClass]="getScreenWidth < 995? '' : 'flex wrapper justify-content-between'">
                <div class="" [ngClass]="getScreenWidth < 995? '' : 'container'">
                  <div class="" [ngClass]="getScreenWidth < 995? '' : 'row'">
                    <div class="" [ngClass]="getScreenWidth < 995? '' : 'col-6 col-md-4'">
                      <li [ngClass]="getScreenWidth < 995? 'has-submenu' : 'test1 py-3'">
                        <a href="/about-us" class="ea-green nav-title font-bold">NOUS CONNAÎTRE</a>
                        <ul [ngClass]="getScreenWidth < 995? 'submenu second open' : 'test list-unstyled'">
                          <li><a class="nav-link-ref text-dark font-bold" routerLink="/about-us">Qui sommes-nous ?</a></li>
                          <li><a class="nav-link-ref text-dark font-bold" routerLink="/historical-landmarks">Repères historiques</a></li>
                          <li><a class="nav-link-ref text-dark font-bold" routerLink="/missions-and-values">Missions, esprit et valeurs</a></li>
                          <li><a class="nav-link-ref text-dark font-bold" routerLink="/organisation">Notre organisation</a></li>
                          <li><a class="nav-link-ref text-dark font-bold" routerLink="/partners">Nos partenaires</a></li>
                        </ul>
                      </li>
                    </div>
                    <div [ngClass]="getScreenWidth < 995? '' : 'col-6 col-md-4'">
                      <li [ngClass]="getScreenWidth < 995? 'has-submenu' : 'test1 py-3'">
                        <a href="/recruitment" class="ea-green nav-title font-bold">CARRIÈRES</a>
                        <ul [ngClass]="getScreenWidth < 995? 'submenu second open' : 'test list-unstyled'">
                          <li><a class="nav-link-ref text-dark font-bold" routerLink="/occupations">Nos métiers</a></li>
                          <li><a class="nav-link-ref text-dark font-bold" routerLink="/recruitment">Recrutement</a></li>
                        </ul>
                      </li>
                    </div>
                    <div *ngIf="getScreenWidth > 995" class="col-6 col-md-4 flex border-l-2 border-gray-400 justify-center">
                      <img src="assets/images/index/about-header.jpg" class="my-auto" width="300" height="150" alt="test_img"/>
                    </div>
                  </div>
                </div>
              </div>
            </ul>
          </li>

          <li id="li-tech" class="has-submenu submenu-container-1" style="--item-color: #a50000">
           <!-- <a href="/tech-it" routerLink="/tech-it">Tech IT</a> -->
             <a href="javascript:void(0)" (click)="onMenuClick($event)" routerLink="/tech-it">Tech IT</a>
            <span class="menu-arrow"></span>
            <ul id="tech" [ngClass]="getScreenWidth < 995 ? 'submenu megamenu first border-item' : 'rounded shadow-sm border-item list-unstyled'">
              <div class="" [ngClass]="getScreenWidth < 995? '' : 'flex wrapper justify-content-between'">
                <div class="" [ngClass]="getScreenWidth < 995? '' : 'container'">
                  <div class="row">
                    <div [ngClass]="getScreenWidth < 995? '' : 'col-6 col-md-4'">
                      <li [ngClass]="getScreenWidth < 995? 'has-submenu' : 'test1 py-3'">
                        <a href="javascript:void(0)" class="ea-dark-red nav-title font-bold">IT</a>
                        <ul [ngClass]="getScreenWidth < 995? 'submenu second open' : 'test list-unstyled'">
                          <li><a class="nav-link-ref text-dark font-bold" routerLink="/page-maintenance">Informatique décisionnelle</a></li>
                          <li><a class="nav-link-ref text-dark font-bold" routerLink="/page-maintenance">Big Data</a></li>
                          <li><a class="nav-link-ref text-dark font-bold" routerLink="/page-maintenance">Architecture</a></li>
                          <li><a class="nav-link-ref text-dark font-bold" routerLink="/page-maintenance">DevOps</a></li>
                          <li><a class="nav-link-ref text-dark font-bold" routerLink="/page-maintenance">Cloud computing</a></li>
                          <li><a class="nav-link-ref text-dark font-bold" routerLink="/page-maintenance">Intelligence Artificielle</a></li>
                        </ul>
                      </li>

                      <li [ngClass]="getScreenWidth < 995? 'has-submenu' : 'test1 py-3'">
                      <a class="ea-dark-red font-bold" >TECHNOLOGIES</a>
                        <ul [ngClass]="getScreenWidth < 995? 'submenu second open' : 'test list-unstyled'">
                          <li><a class="nav-link-ref text-dark font-bold" routerLink="/page-maintenance">Back-End</a></li>
                          <li><a class="nav-link-ref text-dark font-bold" routerLink="/page-maintenance">Front-End</a></li>
                          <li><a class="nav-link-ref text-dark font-bold" routerLink="/page-maintenance">Mobile</a></li>
                          <li><a class="nav-link-ref text-dark font-bold" routerLink="/page-maintenance">Software</a></li>
                          <li><a class="nav-link-ref text-dark font-bold" routerLink="/page-maintenance">Cloud</a></li>
                        </ul>
                      </li>
                    </div>
                    <div [ngClass]="getScreenWidth < 995? '' : 'col-6 col-md-4'">
                      <li [ngClass]="getScreenWidth < 995? 'has-submenu' : 'test1 py-3'">
                        <a class="ea-dark-red font-bold">RECETTE ET MAINTENANCE OPÉRATIONNELLE</a>
                        <ul [ngClass]="getScreenWidth < 995? 'submenu second open' : 'test list-unstyled'">
                          <li><a class="nav-link-ref text-dark font-bold" routerLink="/page-maintenance">Tests utilisateurs</a></li>
                          <li><a class="nav-link-ref text-dark font-bold" routerLink="/page-maintenance">Tests d'intégration</a></li>
                          <li><a class="nav-link-ref text-dark font-bold" routerLink="/page-maintenance">Test sur l'environnement client</a></li>
                        </ul>
                      </li>

                      <li [ngClass]="getScreenWidth < 995? 'has-submenu' : 'test1 py-3'">
                        <a class="ea-dark-red font-bold">CYBERSÉCURITÉ</a>
                        <ul [ngClass]="getScreenWidth < 995? 'submenu second open' : 'test list-unstyled'">
                          <li><a class="nav-link-ref text-dark font-bold" routerLink="/page-maintenance">Cybersécurité</a></li>
                          <li><a class="nav-link-ref text-dark font-bold" routerLink="/page-maintenance">ToDo : BeSecure</a></li>
                        </ul>
                      </li>
                      </div>
                      <div *ngIf="getScreenWidth > 995" class="col-6 col-md-4 flex border-l-2 border-gray-400 justify-center">
                        <img src="assets/images/index/tech-header.jpg" class="my-auto" width="300" height="250" />
                      </div>
                    </div>
                  </div>
                </div>
            </ul>
          </li>

          <li id="li-agency" class="has-submenu submenu-container-1" style="--item-color:#670079">
           <!--<a href="/agency" routerLink="/agency">Agency</a>-->
           <a href="javascript:void(0)" (click)="onMenuClick($event)" routerLink="/agency">Agency</a>
            <span class="menu-arrow"></span>
            <ul id="agency" [ngClass]="getScreenWidth < 995 ? 'submenu megamenu first border-item' : 'rounded shadow-sm border-item list-unstyled'">
              <div class="" [ngClass]="getScreenWidth < 995? '' : 'flex wrapper justify-content-between'">
                <div class="" [ngClass]="getScreenWidth < 995? '' : 'container'">
                  <div [ngClass]="getScreenWidth < 995? '' : 'row'">
                    <div [ngClass]="getScreenWidth < 995? '' : 'col-6 col-md-4'">
                      <li [ngClass]="getScreenWidth < 995? 'has-submenu' : 'test1 py-3'">
                        <a class="ea-purple font-bold">MARKETING</a>
                        <ul [ngClass]="getScreenWidth < 995? 'submenu second open' : 'test list-unstyled'">
                          <li><a class="nav-link-ref text-dark font-bold " (click)="onChangeTheme('agency')">Stratégie digitale</a></li>
                          <li><a class="nav-link-ref text-dark font-bold" (click)="onChangeTheme('techit')">Communication évènementielle</a></li>
                          <li><a class="nav-link-ref text-dark font-bold" (click)="onChangeTheme('cloud')">Marketing du Contenu</a></li>
                          <li><a class="nav-link-ref text-dark font-bold" routerLink="/page-maintenance">E-mailing & SMSing</a></li>
                        </ul>
                      </li>
                      </div>
                      <div [ngClass]="getScreenWidth < 995? '' : 'col-6 col-md-4'">
                        <li [ngClass]="getScreenWidth < 995? 'has-submenu' : 'test1 py-3'">
                          <a class="ea-purple font-bold">CRÉATIVITÉ</a>
                          <ul [ngClass]="getScreenWidth < 995? 'submenu second open' : 'test list-unstyled'">
                            <li><a class="nav-link-ref text-dark font-bold" routerLink="/page-maintenance">Production audiovisuelle</a></li>
                            <li><a class="nav-link-ref text-dark font-bold" routerLink="/page-maintenance">Design Web</a></li>
                            <li><a class="nav-link-ref text-dark font-bold" routerLink="/page-maintenance">Création publicitaire</a></li>
                          </ul>
                        </li>
                      </div>
                      <div *ngIf="getScreenWidth > 995" class="col-6 col-md-4 flex border-l-2 border-gray-400 justify-center">
                        <img src="assets/images/index/agency-header.jpg" class="my-auto" width="300" height="250" />
                      </div> 
                      <!-- <li class="">
                        <a class="ea-purple">CONSEIL</a> -->
                        <!-- <a href="javascript:void(0)" (click)="onMenuClick($event)">CONSEIL</a>
                        <span class="-arrow"></span> -->
                        <!-- <ul class=" ">
                          <li><a class="nav-link-ref" routerLink="/">To do</a></li>
                        </ul>
                      </li> -->
                  </div>
                </div>
              </div>
            </ul>
          </li>

          <li id="li-cloud" class="has-submenu submenu-container-1" style="--item-color: #e1a725">
            <!--<a href="/cloud" routerLink="/cloud">Cloud</a> -->
            <a href="javascript:void(0)" (click)="onMenuClick($event)" routerLink="/cloud">Cloud</a>
            <span class="menu-arrow"></span>
            <ul id="cloud" [ngClass]="getScreenWidth < 995 ? 'submenu megamenu first border-item' : 'rounded shadow-sm border-item list-unstyled'">
              <div class="" [ngClass]="getScreenWidth < 995? '' : 'flex wrapper justify-content-between'">
                <div class="" [ngClass]="getScreenWidth < 995? '' : 'container'">
                  <div [ngClass]="getScreenWidth < 995? '' : 'row'">
                    <div [ngClass]="getScreenWidth < 995? '' : 'col-6 col-md-4'">
                      <li [ngClass]="getScreenWidth < 995? 'has-submenu' : 'test1 py-3'">
                        <a class="ea-yellow font-bold">DOMAINES</a>
                        <ul [ngClass]="getScreenWidth < 995? 'submenu second open' : 'test list-unstyled'">
                          <li><a class="nav-link-ref text-dark font-bold" routerLink="/page-maintenance">Enregistrer un Nom de domaine</a></li>
                          <li><a class="nav-link-ref text-dark font-bold" routerLink="/page-maintenance">Transférer un Nom de domaine</a></li>
                        </ul>
                      </li>

                      <li [ngClass]="getScreenWidth < 995? 'has-submenu' : 'test1 py-3'">
                        <a class="ea-yellow font-bold">HÉBERGEMENT</a>
                        <ul [ngClass]="getScreenWidth < 995? 'submenu second open' : 'test list-unstyled'">
                          <li><a class="nav-link-ref text-dark font-bold" routerLink="/page-maintenance">Hébergement mutualisé</a></li>
                          <li><a class="nav-link-ref text-dark font-bold" routerLink="/page-maintenance">Hébergement cloud</a></li>
                        </ul>
                      </li>
                  </div>
                  <div [ngClass]="getScreenWidth < 995? '' : 'col-6 col-md-4'">
                    <li [ngClass]="getScreenWidth < 995? 'has-submenu' : 'test1 py-3'">
                      <a class="ea-yellow font-bold">SERVEUR</a>
                      <ul [ngClass]="getScreenWidth < 995? 'submenu second open' : 'test list-unstyled'">
                        <li><a class="nav-link-ref text-dark font-bold" routerLink="/page-maintenance">Serveur VPS</a></li>
                        <li><a class="nav-link-ref text-dark font-bold" routerLink="/page-maintenance">Serveur dédié</a></li>
                        <li><a class="nav-link-ref text-dark font-bold" routerLink="/page-maintenance">Serveur cPanel</a></li>
                      </ul>
                    </li>
                    <li [ngClass]="getScreenWidth < 995? 'has-submenu' : 'test1 py-3'">
                      <a class="ea-yellow font-bold">SÉCURITÉ</a>
                      <ul [ngClass]="getScreenWidth < 995? 'submenu second open' : 'test list-unstyled'">
                        <li><a class="nav-link-ref text-dark font-bold" routerLink="/page-maintenance">Certificats SSL</a></li>
                        <li><a class="nav-link-ref text-dark font-bold" routerLink="/page-maintenance">Anti-Malware: GeaTrust</a></li>
                        <li><a class="nav-link-ref text-dark font-bold" routerLink="/page-maintenance">Sceau SiteLock</a></li>
                      </ul>
                    </li>
                  </div>
                  <div *ngIf="getScreenWidth > 995" class="col-6 col-md-4 flex border-l-2 border-gray-400 justify-center">
                    <img src="assets/images/index/cloud-header.jpg" class="my-auto" width="300" height="250" />
                  </div>
                </div>
              </div>
            </div>
          </ul>
        </li>

          <li id="li-training" class="has-submenu" style="--item-color: #0060b7">
            <a href="/training" routerLink="/training">Training</a>
            <!-- <a href="javascript:void(0)" (click)="onMenuClick($event)">Training</a>
            <span class="menu-arrow"></span>
            <ul [ngClass]="getScreenWidth < 995 ? 'submenu megamenu first border-item' : 'rounded shadow-sm border-item list-unstyled'" id="training">
              <div class="" [ngClass]="getScreenWidth < 995? '' : 'flex wrapper justify-content-between'">
                <div class="" [ngClass]="getScreenWidth < 995? '' : 'container'">
                  <div [ngClass]="getScreenWidth < 995? '' : 'row'">
                    <div [ngClass]="getScreenWidth < 995? '' : 'col-6 col-md-4'">
                      <li [ngClass]="getScreenWidth < 995? 'has-submenu' : 'test1 py-3'">
                        <a class="ea-blue font-bold">MANAGEMENT DE PROJET</a>
                        <ul [ngClass]="getScreenWidth < 995? 'submenu second open' : 'test list-unstyled'">
                          <li><a class="nav-link-ref text-dark font-bold" routerLink="/page-maintenance">Agilité</a></li>
                          <li><a class="nav-link-ref text-dark font-bold" routerLink="/page-maintenance">Conduite de projet</a></li>
                        </ul>
                      </li>
                      <li [ngClass]="getScreenWidth < 995? 'has-submenu' : 'test1 py-3'">
                        <a class="ea-blue font-bold">MANAGEMENT DE PROJET</a>
                        <ul [ngClass]="getScreenWidth < 995? 'submenu second open' : 'test list-unstyled'">
                          <li><a class="nav-link-ref text-dark font-bold" routerLink="/page-maintenance">Management d'entreprise</a></li>
                          <li><a class="nav-link-ref text-dark font-bold" routerLink="/page-maintenance">Développement personnel</a></li>
                        </ul>
                      </li>
                      <li [ngClass]="getScreenWidth < 995? 'has-submenu' : 'test1 py-3'">
                        <a class="ea-blue font-bold">MARKETING</a>
                        <ul [ngClass]="getScreenWidth < 995? 'submenu second open' : 'test list-unstyled'">
                          <li><a class="nav-link-ref text-dark font-bold" routerLink="/page-maintenance">Marketing stratégique</a></li>
                          <li><a class="nav-link-ref text-dark font-bold" routerLink="/page-maintenance">Marketing digital</a></li>
                        </ul>
                      </li>
                      <li [ngClass]="getScreenWidth < 995? 'has-submenu' : 'test1 py-3'">
                        <a class="ea-blue font-bold">DATA</a>
                        <ul [ngClass]="getScreenWidth < 995? 'submenu second open' : 'test list-unstyled'">
                          <li><a class="nav-link-ref text-dark font-bold" routerLink="/page-maintenance">Business intelligence</a></li>
                          <li><a class="nav-link-ref text-dark font-bold" routerLink="/page-maintenance">Big Data</a></li>
                        </ul>
                      </li>
                    </div>
                    <div [ngClass]="getScreenWidth < 995? '' : 'col-6 col-md-4'">
                      <li [ngClass]="getScreenWidth < 995? 'has-submenu' : 'test1 py-3'">
                        <a class="ea-blue font-bold">DÉVELOPPEMENT</a>
                        <ul [ngClass]="getScreenWidth < 995? 'submenu second open' : 'test list-unstyled'">
                          <li><a class="nav-link-ref text-dark font-bold" routerLink="/page-maintenance">Applicatif</a></li>
                          <li><a class="nav-link-ref text-dark font-bold" routerLink="/page-maintenance">Métier du test</a></li>
                        </ul>
                      </li>
                      <li [ngClass]="getScreenWidth < 995? 'has-submenu' : 'test1 py-3'">
                        <a class="ea-blue font-bold">INFRASTRUCTURE SYSTÈME & RÉSEAUX</a>
                        <ul [ngClass]="getScreenWidth < 995? 'submenu second open' : 'test list-unstyled'">
                          <li><a class="nav-link-ref text-dark font-bold" routerLink="/page-maintenance">Réseaux</a></li>
                          <li><a class="nav-link-ref text-dark font-bold" routerLink="/page-maintenance">Système</a></li>
                        </ul>
                      </li>
                      <li [ngClass]="getScreenWidth < 995? 'has-submenu' : 'test1 py-3'">
                        <a class="ea-blue font-bold">CYBERSÉCURITÉ ET COMPLIANCE</a>
                        <ul [ngClass]="getScreenWidth < 995? 'submenu second open' : 'test list-unstyled'">
                          <li><a class="nav-link-ref text-dark font-bold" routerLink="/page-maintenance">Sécurité de l’espace de travail numérique</a></li>
                          <li><a class="nav-link-ref text-dark font-bold" routerLink="/page-maintenance">Sécurité du cloud</a></li>
                        </ul>
                      </li>
                    </div>
                    <div *ngIf="getScreenWidth > 995" class="col-6 col-md-4 flex border-l-2 border-gray-400 justify-center">
                      <img src="https://via.placeholder.com/300x250" class="my-auto" width="300" height="250" />
                    </div>
                  </div>
                </div>
              </div>
            </ul> -->
          </li>

          <!-- <li id="li-solution" class="has-submenu">
            <a href="javascript:void(0)" routerLink="/page-maintenance">Solutions</a>
            <a href="javascript:void(0)" (click)="onMenuClick($event)">Solutions</a>
            <span class="menu-arrow"></span>
            <small class="tts-nav-arrow"></small>
              <ul id="solution" [ngClass]="getScreenWidth < 995 ? 'submenu megamenu first border-top' : 'rounded shadow-sm border-top list-unstyled'">
                <div class="" [ngClass]="getScreenWidth < 995? '' : 'flex wrapper justify-content-between'">
                  <div class="" [ngClass]="getScreenWidth < 995? '' : 'container'">
                    <div [ngClass]="getScreenWidth < 995? '' : 'row'">
                      <div [ngClass]="getScreenWidth < 995? '' : 'col-6 col-md-4'">
                        <li [ngClass]="getScreenWidth < 995? 'has-submenu' : 'test1 py-3'">
                          <a class="ea-green font-bold">APPLICATIONS ET PLATEFORMES</a>
                          <ul [ngClass]="getScreenWidth < 995? 'submenu second open' : 'test list-unstyled'">
                            <li><a class="nav-link-ref text-dark font-bold" routerLink="/page-maintenance">EA Platformes</a></li>
                            <li><a class="nav-link-ref text-dark font-bold" routerLink="/page-maintenance">Visioconférence</a></li>
                            <li><a class="nav-link-ref text-dark font-bold" routerLink="/page-maintenance">E-Learning</a></li>
                          </ul>
                        </li>
                        <li [ngClass]="getScreenWidth < 995? 'has-submenu' : 'test1 py-3'">
                          <a class="ea-green font-bold">DIGITAL CONSULTING</a>
                          <ul [ngClass]="getScreenWidth < 995? 'submenu second open' : 'test list-unstyled'">
                            <li><a class="nav-link-ref text-dark font-bold" routerLink="/page-maintenance">Stratégie Digitale</a></li>
                            <li><a class="nav-link-ref text-dark font-bold" routerLink="/page-maintenance">Expérience client</a></li>
                            <li><a class="nav-link-ref text-dark font-bold" routerLink="/page-maintenance">Digitalisation des</a></li>
                          </ul>
                        </li>
                      </div>
                      <div [ngClass]="getScreenWidth < 995? '' : 'col-6 col-md-4'">
                        <li [ngClass]="getScreenWidth < 995? 'has-submenu' : 'test1 py-3'">
                          <a class="ea-green font-bold">PRODUITS</a>
                          <ul [ngClass]="getScreenWidth < 995? 'submenu second open' : 'test list-unstyled'">
                            <li><a class="nav-link-ref text-dark font-bold" routerLink="/page-maintenance">Messagerie instantanée</a></li>
                            <li><a class="nav-link-ref text-dark font-bold" routerLink="/page-maintenance">SMS & VSMS</a></li>
                            <li><a class="nav-link-ref text-dark font-bold" routerLink="/page-maintenance">E-mailing</a></li>
                            <li><a class="nav-link-ref text-dark font-bold" routerLink="/page-maintenance">Facebook</a></li>
                            <li><a class="nav-link-ref text-dark font-bold" routerLink="/page-maintenance">Whatsapp Business</a></li>
                            <li><a class="nav-link-ref text-dark font-bold" routerLink="/page-maintenance">Platforme de marketing pour les réseaux</a></li>
                          </ul>
                        </li>
                      </div>
                      <div *ngIf="getScreenWidth > 995" class="col-6 col-md-4 flex border-l-2 border-gray-400 justify-center">
                        <img src="https://via.placeholder.com/300x250" class="my-auto" width="300" height="250" />
                      </div>
                    </div>
                  </div>
                  </div>
              </ul>
          </li> -->

          <li id="li-resource" class="has-submenu">
            <span (click)="onMenuClick($event)"  class="link cursor-pointer">Ressources</span>
            <span class="menu-arrow"></span>
            <small class="tts-nav-arrow"></small>
            <ul id="resource" [ngClass]="getScreenWidth < 995 ? 'submenu first border-top' : 'rounded shadow-sm border-top list-unstyled'">
              <li><a class="nav-link-ref font-bold flex justify-start items-center" routerLink="/resource/insight" href="/resource/insight">
                <span class='item-icon flex'><i-feather name="shield" class="fea icon-sm"></i-feather></span>
                <span>Insight</span></a></li>
              <li><a class="nav-link-ref font-bold flex justify-start items-center" routerLink="/resource/webinaires" href="/resource/webinaires">
                <span class='item-icon flex'><i-feather name="video" class="fea icon-sm"></i-feather></span> 
                <span>Webinaires</span>
              </a></li>
              <li><a class="nav-link-ref font-bold flex justify-start items-center" routerLink="/resource/evenement" href="/resource/evenement">
                <span class='item-icon flex'><i-feather name="calendar" class="fea icon-sm"></i-feather></span>
                <span>Évènements</span>
              </a></li>
              <li><a class="nav-link-ref font-bold flex justify-start items-center" routerLink="/resource/presse" href="/resource/presse">
                <span class='item-icon flex'><i-feather name="life-buoy" class="fea icon-sm"></i-feather></span>
                <span>Presse</span>
              </a></li>
            </ul>
          </li>
        </ul>
        <!--end navigation menu-->

        <!-- <div class="buy-menu-btn d-none">
          <a href="javascript: void(0);" target="_blank" class="btn btn-primary">e-ambition.com</a>
        </div> -->
        <!--end login button-->

      </div>
      <!--end navigation-->
    </div>
    <!--end container-->
  </header>

</div>

<!--end header-->
<!-- Navbar End -->
