import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MasterPageComponent } from './core/components/master-page/master-page.component';



const routes: Routes = [
  {
    path: '',
    component: MasterPageComponent,
    children: [
        {path: '', loadChildren: () => import('src/app/main/ea/ea.module').then(m => m.EaModule)},
        {path: 'tech-it', loadChildren: () => import('src/app/main/techit/techit.module').then(m => m.TechItModule)},
        {path: 'agency', loadChildren: () => import('src/app/main/agency/agency.module').then(m => m.AgencyModule)},
        {path: 'cloud', loadChildren: () => import('src/app/main/cloud/cloud.module').then(m => m.CloudModule)},
        {path: 'training', loadChildren: () => import('src/app/main/training/training.module').then(m => m.TrainingModule)},
        {path: 'resource', loadChildren: () => import('src/app/main/resources/resource.module').then(m => m.ResourceModule)},
        {path: 'contactez-nous', loadChildren: () => import('src/app/main/shared/contact/contact-us/contact-us.module').then((m) => m.ContactUsModule)}
      ]
    },
  {
    path: 'page-maintenance',
    loadChildren: () =>
      import('src/app/main/shared/page-maintenance/page-maintenance.module').then((m) => m.PageMaintenanceModule),
  }

  /*
  { path: 'auth-login', component: AuthLoginComponent },
  { path: 'auth-cover-login', component: AuthCoverLoginComponent },
  { path: 'auth-cover-re-password', component: AuthCoverRePasswordComponent },
  { path: 'auth-cover-signup', component: AuthCoverSignupComponent },
  { path: 'auth-login-three', component: AuthLoginThreeComponent },
  { path: 'auth-re-password', component: AuthRePasswordComponent },
  { path: 'auth-re-password-three', component: AuthRePasswordThreeComponent },
  { path: 'auth-signup', component: AuthSignupComponent },
  { path: 'auth-signup-three', component: AuthSignupThreeComponent },
  { path: 'index-onepage', component: IndexOnepageComponent },
  { path: 'page-comingsoon', component: PageComingsoonComponent },
  { path: 'page-comingsoon2', component: PageComingsoon2Component },
  { path: 'page-error', component: PageErrorComponent },
  { path: 'page-thankyou', component: PageThankyouComponent },
  { path: 'email-alert', component: EmailAlertComponent },
  { path: 'email-confirmation', component: EmailConfirmationComponent },
  { path: 'email-invoice', component: EmailInvoiceComponent },
  { path: 'email-password-reset', component: EmailPasswordResetComponent },
  */

];

@NgModule({
  imports: [
      RouterModule.forRoot(routes,
          { scrollPositionRestoration: 'enabled',
                  scrollOffset: [0, 0],
                  // Enable scrolling to anchors
                  anchorScrolling: 'enabled'})
  ],
  exports: [
      RouterModule
  ]
})
export class AppRoutingModule { }
