import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SeoService } from './helper/SeoService';
// import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{

  constructor(
      private router: Router,
      private seoService: SeoService) {

    /*this.router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        window['Unicons']['refresh']();
      }

      if (!(event instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
      // this.seoService.generateTags({title: 'E-AMBITION Inside'});
    });
    */
  }

    /**
     * On init
     */
     ngOnInit(): void
     {
         // Force HTTPS
         /*if (environment.production) {
             if (location.protocol === 'http:') {
                 window.location.href = location.href.replace('http', 'https');
             }
         }*/
     }

}
