<div *ngIf="!hideFooter">
    <div *ngIf="footerVariant == 'bg-light';else execute_block">
        <!-- Footer Start -->
        <footer class="footer bg-light">
            <div class="">
                <div class="row">
                    <div class="col-lg-4 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
                        <a href="#" class="logo-footer">
                            <img src="assets/images/logo.svg" width="240" alt="">
                        </a>
                        <p class="mt-4 text-muted">Start working with Landrick that can provide everything you need to
                            generate awareness, drive traffic, connect.</p>
                        <ul class="list-unstyled social-icon social mb-0 mt-4">
                            <li class="list-inline-item"><a href="javascript:void(0)" class="rounded">
                                    <i-feather name="facebook" class="fea icon-sm fea-social"></i-feather>
                                </a></li>
                            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                                    <i-feather name="instagram" class="fea icon-sm fea-social"></i-feather>
                                </a></li>
                            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                                    <i-feather name="twitter" class="fea icon-sm fea-social"></i-feather>
                                </a></li>
                            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                                    <i-feather name="linkedin" class="fea icon-sm fea-social"></i-feather>
                                </a></li>
                        </ul>
                        <!--end icon-->
                    </div>
                    <!--end col-->

                    <div class="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <h5 class="text-dark footer-head">Company</h5>
                        <ul class="list-unstyled footer-list mt-4">
                            <li><a routerLink="/page-aboutus" class="text-muted">
                                        À propos de nous</a></li>
                        </ul>
                    </div>
                    <!--end col-->

                    <div class="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <h5 class="text-dark footer-head">Liens utiles</h5>
                        <ul class="list-unstyled footer-list mt-4">
                            <li><a routerLink="/page-terms" class="text-muted">
                                    Item</a></li>
                        </ul>
                    </div>
                    <!--end col-->

                    <div class="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <h5 class="text-dark footer-head">Newsletter</h5>
                        <p class="mt-4 text-muted">Inscrivez-vous et recevez les derniers conseils par e-mail.</p>

                        <form>
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="foot-subscribe foot-white mb-3">
                                        <label class="form-label">Entrez votre e-mail<span
                                                class="text-danger">*</span></label>
                                        <div class="form-icon position-relative">
                                            <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                                            <input type="email" name="email" id="emailsubscribe"
                                                class="form-control bg-light border ps-5 rounded"
                                                placeholder="Votre email : " required>
                                        </div>
                                    </div>
                                </div>
                                <!--end col-->
                                <div class="col-lg-12">
                                    <div class="d-grid">
                                        <input type="submit" id="submitsubscribe" name="send"
                                            class="btn btn-soft-primary" value="Abonner">
                                    </div>
                                </div>
                                <!--end col-->
                            </div>
                            <!--end row-->
                        </form>
                    </div>
                    <!--end col-->
                </div>
                <!--end row-->
            </div>
            <!--end container-->
        </footer>
        <!-- Footer End -->
    </div>
    <ng-template #execute_block>
        <footer class="footer">
            <div class="">
                <div class="row">
                    <div class="col-lg-4 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
                        <!-- Logo | About company -->
                        <a href="#" class="logo-footer">
                            <img src="assets/images/logo-light.svg" width="240" height="50" alt="E-AMBITION" class="company-logo">
                        </a>
                        <p class="mt-4 mb-4" style="text-align: justify;">
                            E-AMBITION est une société spécialisée dans l'accompagnement, la modélisation et la réalisation des projets informatiques. Notre équipe composée de développeurs, de concepteurs et d'analystes compétents qui sont toujours prêts à relever votre défi.
                        </p>

                        <!-- Social media links -->
                        <!-- <h5 class="footer-head">Suivez-nous</h5>
                        <ul class="list-unstyled social-icon foot-social-icon mb-0 mt-4">
                            <li class="list-inline-item"><a href="javascript:void(0)" class="rounded">
                                    <i-feather name="facebook" class="fea icon-sm fea-social"></i-feather>
                                </a></li>
                            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                                    <i-feather name="instagram" class="fea icon-sm fea-social"></i-feather>
                                </a></li>
                            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                                    <i-feather name="twitter" class="fea icon-sm fea-social"></i-feather>
                                </a></li>
                            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                                    <i-feather name="linkedin" class="fea icon-sm fea-social"></i-feather>
                                </a></li>
                        </ul> -->
                        <!--end icon-->

                        <div class="col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                            <h5 class="text-light footer-head">À propos</h5>
                            <ul class="list-unstyled footer-list mt-4">
                                <li><a [routerLink]="'/about-us'" class="text-foot">Qui sommes-nous ?</a></li>
                                <li><a routerLink="/page-aboutus" class="text-foot">Pôles d'expertise sectoriels</a></li>
                                <li><a routerLink="/recruitment" class="text-foot">Recrutement</a></li>
                                <!-- <li><a routerLink="/page-aboutus" class="text-foot">Nos solutions</a></li> -->
                            </ul>
                        </div>
                    </div>
                    <!--end col-->

                    <!-- Activities -->
                    <div class="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <h5 class="text-light footer-head">NOS PÔLES D'ACTIVITÉ</h5>
                        <ul class="list-unstyled footer-list mt-4">                      
                            <li><a routerLink="/tech-it" class="text-foot activity-item">
                                <div class="icon">
                                    <span href="" class="icon-link">
                                      <img src="assets/images/logo/ea-s.svg" alt="" class="icon-image face front">
                                      <img src="assets/images/logo/ea-s.svg" alt="" class="icon-image face right">
                                    </span>
                                  </div>
                                <span style="color: #e0433F;">Tech IT</span>
                            </a></li> 
                            <li><a routerLink="/agency" class="text-foot activity-item">
                                <div class="icon">
                                    <span href="" class="icon-link">
                                      <img src="assets/images/logo/ea-a.svg" alt="" class="icon-image face front">
                                      <img src="assets/images/logo/ea-a.svg" alt="" class="icon-image face right">
                                    </span>
                                </div>
                                <span style="color: #e16af5;">Agency</span>
                            </a></li> 
                            <li><a routerLink="/cloud" class="text-foot activity-item">
                                <div class="icon">
                                    <span href="" class="icon-link">
                                      <img src="assets/images/logo/ea-h.svg" alt="" class="icon-image face front">
                                      <img src="assets/images/logo/ea-h.svg" alt="" class="icon-image face right">
                                    </span>
                                </div>
                                <span style="color: #e1a725;">Cloud</span>
                            </a></li> 
                            <li><a routerLink="/training" class="text-foot activity-item">
                                <div class="icon">
                                    <span href="" class="icon-link">
                                      <img src="assets/images/logo/ea-t.svg" alt="" class="icon-image face front">
                                      <img src="assets/images/logo/ea-t.svg" alt="" class="icon-image face right">
                                    </span>
                                </div>
                                <span style="color: #2a87dc;">Training</span>
                            </a></li> 
                        </ul>
                    </div>
                    <!--end col-->

                    <!-- A PROPOS -->
                    <div class="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <h5 class="text-light footer-head">Informations</h5>
                        <ul class="list-unstyled footer-list mt-4">
                            <li><a routerLink="/page-aboutus" class="text-foot">Services</a></li>
                            <li><a routerLink="/recruitment" class="text-foot">Nous rejoindre</a></li>
                            <li><a routerLink="/page-aboutus" class="text-foot">Boîte à idées</a></li>
                            <li><a routerLink="/contactez-nous" class="text-foot">Nous contacter</a></li>
                        </ul>
                        <ul id="info-location" class="list-unstyled footer-list mt-4">
                            <li>
                                <span>International :</span>
                                <span>+33 1 84 23 00 16</span>
                            </li>
                            <li>
                                <span>Adresse:</span>
                                <span>Hay Al Kora, Résidence AlMansour, N°6, 2ème étage, Rabat 10090, Maroc</span>
                            </li>
                        </ul>
                    </div>
                    <!--end col-->

                    <div class="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <h5 class="text-light footer-head">Newsletter</h5>
                        <p class="mt-4">Inscrivez-vous et recevez les derniers conseils par e-mail.</p>
                        <form>
                            <div class="row">
                                <form [formGroup]="userEmail">
                                    <div class="col-lg-12">
                                        <div class="form-group foot-subscribe foot-white mb-3">
                                            <label class="form-label">Entrez votre email <span class="text-danger">*</span></label>
                                            <div class="form-icon position-relative">
                                                <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                                                <input 
                                                    [class.is-invalid]="userEmail.get('email').invalid && userEmail.get('email').touched"
                                                    type="email" 
                                                    name="email" 
                                                    id="emailsubscribe" 
                                                    class="form-control ps-5 rounded" 
                                                    placeholder="Votre email : " 
                                                    required
                                                    formControlName="email"
                                                >
                                            </div>
                                            <div *ngIf="(email.invalid && email.touched) || email.dirty">
                                                <small *ngIf="email.errors?.required" class="text-danger">Email is required</small>
                                                <!--question mark(?) is a safe navigation operator-->
                                                <small *ngIf="email.errors?.pattern" class="text-danger">Please provide a valid email address</small>
                                              </div>
                                        </div>
                                    </div>
                                    <!--end col-->
                                    <div class="col-lg-12">
                                        <div class="d-grid">
                                            <input type="submit" id="submitsubscribe" name="send"
                                                class="btn btn-soft-primary" value="Abonner" (click)="sendMail(email)">
                                        </div>
                                    </div>
                                    <!--end col-->
                                </form>
                                <!-- Social media links -->
                                <h5 class="footer-head mt-4">Suivez-nous</h5>
                                <ul id="social-media-links" class="list-unstyled social-icon foot-social-icon mb-0 mt-2">
                                    <li class="list-inline-item">
                                        <a href="https://facebook.com/eambition/" class="rounded flex items-center justify-center" aria-label="facebook">
                                            <i-feather name="facebook" class="fea icon-sm fea-social"></i-feather>
                                        </a>
                                    </li>
                                    <li class="list-inline-item ms-1">
                                        <a href="https://www.instagram.com/eambitioncom" class="rounded flex items-center justify-center" aria-label="instagram">
                                            <i-feather name="instagram" class="fea icon-sm fea-social"></i-feather>
                                        </a>
                                    </li>
                                    <!-- <li class="list-inline-item ms-1">
                                        <a href="javascript:void(0)" class="rounded flex items-center justify-center">
                                            <i-feather name="twitter" class="fea icon-sm fea-social"></i-feather>
                                        </a>
                                    </li> -->
                                    <li class="list-inline-item ms-1">
                                        <a href="https://www.linkedin.com/company/e-ambition/" class="rounded flex items-center justify-center" aria-label="linkedin">
                                            <i-feather name="linkedin" class="fea icon-sm fea-social"></i-feather>
                                        </a>
                                    </li>
                                </ul>
                                <!--end icon-->
                            </div>
                            <!--end row-->
                        </form>
                    </div>
                    <!--end col-->
                </div>
                <!--end row-->
            </div>
            <!--end container-->
        </footer>
    </ng-template>
</div>
<!--end footer-->
<footer class="footer footer-bar">
    <div class="container text-center">
        <div class="row align-items-center">
            <div class="">
                <div class="">
                    <p class="text-center justify-center">
                        Copyright © 2015 / {{year}} All Rights Reserved
                        <i-feather name="heart" class="fea icon-sm text-danger pt-1"></i-feather>
                        <a target="_blank" href="https://www.e-ambition.com" class="text-reset"> e-ambition.com</a>
                    </p>
                </div>
            </div>
            <!--end col-->
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</footer>
<!--end footer-->
