import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';

const eaRoutes: Routes = [
    {
        path: '',
        loadChildren: () =>
            import('src/app/main/ea/index/index.module').then((m) => m.IndexModule),
    },
    {
        path: 'occupations',
        loadChildren: () =>
            import('src/app/main/ea/about/occupations/occupations.module').then((m) => m.OccupationsModule),
    },
    {
        path: 'historical-landmarks',
        loadChildren: () =>
            import('src/app/main/ea/about/historical-landmarks/hitorical-landmarks.module').then((m) => m.HistoricalLandmarksModule),
    },
    {
        path: 'about-us',
        loadChildren: () =>
            import('src/app/main/ea/about/about-us/about-us.module').then((m) => m.AboutUsModule),
    },
    {
        path: 'recruitment',
        loadChildren: () =>
            import('src/app/main/ea/about/recruitment/recruitment.module').then((m) => m.RecruitmentModule),
    },
    {
        path: 'partners',
        loadChildren: () =>
            import('src/app/main/ea/about/our-partners/our-partners.module').then((m) => m.OurPartnersModule),
    },
    {
        path: 'organisation',
        loadChildren: () =>
            import('src/app/main/ea/about/our-organisation/our-organisation.module').then((m) => m.OurOrganisationModule),
    },
    {
        path: 'missions-and-values',
        loadChildren: () =>
            import('src/app/main/ea/about/missions-and-values/missions-and-values.module').then((m) => m.MissionsAndValuesModule),
    },
    {
        path: 'offers',
        loadChildren: () =>
            import('src/app/main/ea/about/offers/offers.module').then((m) => m.OffersModule),
    },
    // redirect
    {path: '**', redirectTo: 'page-maintenance'},

    // { path: 'occupations', component: OccupationsComponent},
    // { path: 'about-us', component: AboutUsComponent},
    // { path: 'recruitment', component: RecruitmentComponent},
    // { path: 'partners', component: OurPartnersComponent},
    // { path: 'organisation', component: OurOrganisationComponent},
    // { path: 'missions-and-values', component: MissionsAndValuesComponent},
    // { path: 'historical-landmarks', component: HistoricalLandmarksComponent},
    // { path: 'contactez-nous', component: ContactUsComponent},
];

@NgModule({
    imports: [RouterModule.forChild(eaRoutes)],
    exports: [RouterModule],
})
export class EARoutingModule {
}
